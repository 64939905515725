




import PropertyCreateUpdateForm from '@/components/PropertyCreateUpdateForm.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PropertyCreate',

  components: {
    PropertyCreateUpdateForm,
  },

  props: {
    propertyKey: {
      type: Number,
      required: true,
    },
  },
})
